/* 

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}


.contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-textt {
  position: absolute;
  top: 0;
  left: 0;
  width: 28%;
  height: 100%;
  background-color: rgb(125, 35, 35);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}



.bg1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url('../assets/fin.jpg');
  background-size: cover;
  background-position: center;
 
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5; 
}

.content {
  position: absolute;
  font-weight: bold;
  font-size: 2rem;
  color: rgb(0, 0, 0);
  bottom: 40px; 
  left: 20px; 
}

.navigation {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  position: absolute;
  bottom: 20px; 
  left: 20px; 
}

.navigation span {
  margin-right: 5px; 
}

.contact {
  color: rgb(255, 255, 255);
  border: none;
}
.arrow-box {
  position: absolute;
  top: 50%;
  right: -100px;
  transform: translateY(-50%);
  border-top: 188px solid transparent;
  border-bottom: 188px solid transparent;
  border-left: 100px solid rgb(125, 35, 35); 
}


.glass1 {
  width: 100%;
  padding: 20px;

  background-color: aliceblue;
}


.service-details {
  padding: 20px;
  width: 95%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center; 
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}


.btn {
  padding: 5px 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.btn:hover {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 0.5px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  animation: pulse 0.6s ease-in-out;
}


.office-info1 {
  text-align: center;
  margin-bottom: 20px;
}

.title1 {
  margin: 0;
  font-size: 1.8rem;
}

.title2 {
  margin: 0;
  font-size: 1.4rem;
}

.office {
  display: flex;
  flex-direction: column;
  text-align: center; 
  align-items: center; 
  justify-content: center;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}


.office p {
  margin: 10px 0; 
  font-size: 1rem;
}



.office > p > a {
  text-decoration: none;
  color: black;
}

.office > p > a:hover {
  padding: 5px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 0.5px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  animation: pulse 0.6s ease-in-out;
  }
  

  @keyframes pulse {
  0% {
  transform: scale(1);
  text-shadow: none;
  }
  50% {
  transform: scale(1.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  100% {
  transform: scale(1);
  text-shadow: none;
  }
  }
  .slick-prev,
.slick-next {
  display: none !important; 
}

  @media screen and (max-width: 768px) {
  .contact-container {
  height: 40vh;
  }
  
  .content {
  font-size: 1.5rem;
  bottom: 20px;
  }
  
  .navigation {
  font-size: 0.8rem;
  bottom: 10px;
  }
  
  .glass {
  width: 90%;
  }
  
  .office {
  width: 90%;
  }
  
  .office p {
  font-size: 0.9rem;
  }
  }
  
  @media screen and (max-width: 480px) {
  .contact-container {
  height: 30vh;
  }
  
  .content {
  font-size: 1.2rem;
  bottom: 10px;
  }
  
  .navigation {
  font-size: 0.7rem;
  bottom: 5px;
  }
  
  .glass {
  width: 90%;
  }
  
  .office {
  width: 80%;
  }
  
  .office p {
  font-size: 0.8rem;
  }
  } */


  General Styles
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Contact Container */
.contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* .carousel-textt {
  position: absolute;
  top: 0;
  left: 0;
  width: 28%;
  height: 100%;
  background-color: rgb(125, 35, 35);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
} */


.bg1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/fin.jpg');
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5; /* Adjust overlay opacity as desired */
}

.content {
  font-size: 36px;
  font-weight: bold;
}

.navigation {
  font-size: 18px;
  margin-top: 10px;
}

.arrow-box {
  /* Your arrow box styles */
}

.glass1 {
  padding: 20px;
  background: aliceblue;
  border-radius: 10px;
  margin: 20px;
}

.office-info1 .title1,
.office-info1 .title2 {
  text-align: center;
}

.office {
  text-align: center;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.service-item {
  width: 150px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.service-item:hover {
  background-color:  rgba(125, 35, 35,0.5);
}




.service-description {
  margin-top: 20px;
  text-align: justify;
}

.btn {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #0056b3;
}
/* .content {
  position: absolute;
  font-weight: bold;
  font-size: 2rem;
  color: rgb(0, 0, 0);
  bottom: 40px; 
  left: 20px;
}

.navigation {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  position: absolute;
  bottom: 20px;
  left: 20px; 
} */

.navigation span {
  margin-right: 5px; /* Add some spacing between HOME and CONTACT */
}

.contact {
  color: rgb(255, 255, 255);
  border: none;
}
/* 
.arrow-box {
  position: absolute;
  top: 50%;
  right: -100px; 
  transform: translateY(-50%);
  border-top: 188px solid transparent;
  border-bottom: 188px solid transparent;
  border-left: 100px solid rgb(125, 35, 35); 
}


.glass1 {
  width: 100%;
  padding: 20px;
  background-color: aliceblue;
} */

/* Service Details */
.service-details {
  padding: 20px;
  width: 95%;
  display: flex;
  flex-direction: column;
  text-align: center; /* Center-align text */
  align-items: center; /* Center items horizontally */
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

/* Back Button */
/* .btn {
  padding: 5px 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.btn:hover {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 0.5px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  animation: pulse 0.6s ease-in-out;
} */

/* 
.office-info1 {
  text-align: center;
  margin-bottom: 20px;
}

.title1 {
  margin: 0;
  font-size: 1.8rem;
}

.title2 {
  margin: 0;
  font-size: 1.4rem;
} */

/* Office Service List */
/* .office {
  display: flex;
  flex-direction: column;
  text-align: center; 
  align-items: center;
  justify-content: center; 
  width: 80%; 
  margin: 20px auto;
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
} */

/* Office Service List Items */
.office p {
  margin: 10px 0; /* Adjust margin for spacing between items */
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Icon */
.office p .icon1 {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

/* Link Hover Effect */
.office > p > a {
  text-decoration: none;
  color: black;
}

.office > p > a:hover {
  padding: 5px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 0.5px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  animation: pulse 0.6s ease-in-out; /* Applies the pulse animation on hover */
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    text-shadow: none;
  }
  50% {
    transform: scale(1.1);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  100% {
    transform: scale(1);
    text-shadow: none;
  }
}

.slick-prev,
.slick-next {
  display: none !important; /* Hide the arrows */
}

/* Services Grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  width: 100%;
}
.service-grid.active {
  color: rgb(125, 35, 35); /* Highlight color */
}

.service-item {
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.service-item.active {
  background-color: rgb(125, 35, 35); /* Change this to the desired background color */
}

.icon1 {
  width: 50px;
  height: 50px;
   /* Placeholder for icon */
  
  margin-bottom: 10px;
  transition: background-color 0.3s;
}



.service-description {
  margin-top: 20px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .contact-container {
    height: 40vh;
  }

  .content {
    font-size: 1.5rem;
    bottom: 20px;
  }

  .navigation {
    font-size: 0.8rem;
    bottom: 10px;
  }

  .glass1 {
    width: 90%;
  }

  .office {
    width: 90%;
  }

  .office p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .contact-container {
    height: 30vh;
  }

  .content {
    font-size: 1.2rem;
    bottom: 10px;
  }

  .navigation {
    font-size: 0.7rem;
    bottom: 5px;
  }

  .glass1 {
    width: 90%;
  }

  .office {
    width: 80%;
  }

  .office p {
    font-size: 0.8rem;
  }
}
