

/* Define general styles for the header */
.header-container1 {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.028);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

/* Styles for the logo */
.logo-nav1 .heading1 {
  color: #25557b; /* Dark blue */
  font-weight: 500;
  font-size: 1.2rem;
  text-decoration: none;
  margin-left: 10px;
}

/* Styles for the navigation links */
.links-nav1 a {
  font-weight: 300;
  font-size: 1rem;
  margin: 0 1rem;
  color: #25557b; /* Dark blue */
  text-decoration: none;
  position: relative;
}

/* Hover effect on navigation links */
.links-nav1 a:hover {
  color: #ff7a00; /* Orange for hover effect */
}

/* Bold style for specific links */
.bold-link1 {
  font-weight: bold;
}

/* Styles for search icon */
.links-nav1 a .fa-search {
  font-size: 1rem;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .header-container1 {
    flex-direction: column;
    align-items: center;
  }
  
  .links-nav1 {
    margin-top: 1rem;
  }
}
