
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Contact Container */
.contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/1.jpg');
  background-size: cover;
  background-position: center;
}
.oil1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url('../assets/oil.jpg');
  background-size: cover;
  background-position: center;
   /* Adjust opacity as desired */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  font-weight: bold;
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
}

.navigation {
  font-size: 1rem;
  color: white;
}

.navigation span {
  margin-right: 5px;
}

.contact {
  color: #2596BE;
}

/* Products Info */
.products-info {
  padding: 20px;
  background-image: url('../assets/122.jpg');
  background-size: cover;
  background-position: center;
  position: relative; 
}


.back-button {
  background-color: #2596BE;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  text-decoration: none;
  display: inline-block; /* Ensures the element respects margins */
  margin-bottom: 20px;
}

.back-button:hover {
  background-color: #2075A8; /* Darker shade when hovered */
}

/* Tabs Wrapper */
.tabs-wrapper {
  overflow-x: auto;
  margin-bottom: 20px;
  white-space: nowrap;
}

.tabs-wrapper::-webkit-scrollbar {
  display: none;
}

/* Tabs Container */
.tabs-container {
  display: flex;
  justify-content: center;
}

/* Tab */
.tab {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.tab:hover,
.tab.active {
  background-color: #e0e0e0;
}

/* Tab Content */
.tab-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Product */
.product {
  text-align: center;
  margin: 10px;
  width: calc(25% - 20px);
  transition: opacity 0.5s ease;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  transition: transform 0.3s ease;
}
.product:hover {
  transform: scale(1.1); /* Scale the image on hover */
}
.product img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.product p {
  margin-top: 10px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .contact-container {
    height: 40vh;
  }

  .content {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  .navigation {
    font-size: 0.8rem;
  }
  .tabs-container {
    display: inline-flex;
  }

  .tab {
    margin-bottom: 10px;
  }
 
  .product {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .contact-container {
    height: 30vh;
  }

  .content {
    font-size: 1.2rem;
  }

  .navigation {
    font-size: 0.7rem;
  }
  .tabs-container {
    display: inline-flex;
  }
  .tab {
    padding: 8px 16px;
    margin-right: 5px;
  }

  .product {
    width: calc(100% - 20px);
  }
  .product:hover{
    transform: scale(1.0);
  }
}
