/* 

 body {
  background-color: #E2E2E4;
  font-family: 'Roboto', sans-serif;
}

.header-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease, top 0.3s ease;

  
}

.header-container.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.logo-nav a img {
  width: 8rem;
  height: auto;
}

.links-nav a {
  font-weight: 300;
  font-size: 1rem;
  margin: 0 1rem;
  color: #25557b; 
  text-decoration: none;
  position: relative; 
}

.links-nav a:hover {
  color: #ff7a00; 
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: antiquewhite;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
  top: 100%;
  left: 0;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-content a {
  color: #333; 
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
}

.links-nav > a:hover .dropdown-content,
.links-nav > a:focus .dropdown-content,
.dropdown-content:hover {
  display: block;
  opacity: 1;
  transform: translateY(0);
  z-index: 1000; 
}


 */

/* Header Container */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease, top 0.3s ease;
}

/* Sticky Header */
.header-container.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Logo Navigation */
.logo-nav a img {
  width: 8rem;
  height: auto;
}

/* Links Navigation */
.links-nav {
  display: flex;
}

.links-nav a {
  font-weight: 300;
  font-size: 1rem;
  margin: 0 1rem;
  color: #25557b;
  text-decoration: none;
  position: relative;
}

/* Dropdown Content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: antiquewhite;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 100%;
  left: 0;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  width: auto; /* Set width to auto */
}

.dropdown-content a {
  display: block;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
}

/* Hover Effect */
.links-nav > a:hover .dropdown-content,
.links-nav > a:focus .dropdown-content,
.dropdown-content:hover {
  display: block;
  opacity: 1;
  transform: translateY(0);
  z-index: 1000;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  /* Header Container Adjustments */
  .header-container {
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
  }

  /* Logo Navigation Adjustments */
  .logo-nav a img {
    width: 6rem;
  }

  /* Links Navigation Adjustments */
  .links-nav {
    margin-top: 1rem;
  }

  .links-nav a {
    margin: 0.5rem 0;
  }

 
 /* Hover Effect for Smaller Screens */
.links-nav > a:hover .dropdown-content,
.links-nav > a:focus .dropdown-content,
.dropdown-content:hover {
  display: block;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  z-index: 1000;
}

}

