/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
}

/* Contact Container */
.contact-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-image: url('../assets/122.jpg'); /* Added background image */
  background-size: cover;
  background-position: center;
}

.bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url('../assets/oil.jpg');
  background-size: cover;
  background-position: center;
   /* Adjust opacity as desired */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.content {
  position: absolute;
  bottom: 40px;
  left: 20px;
  font-weight: bold;
  font-size: 2rem;
  color: white;
}

.navigation {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-weight: 300;
  font-size: 1rem;
  color: white;
}

.navigation span {
  margin-right: 5px;
}

.contact {
  color: rgb(37, 150, 190);
  border: none;
}

/* Office Info */
.office-info {
  padding: 20px;
  background-image: url('../assets/224.jpg');
  background-size: cover;
  background-position: center;
}

.office {
  margin-top: 20px;
}

.office p {
  margin: 5px 0;
  font-size: 0.9rem;
}

/* Contact Form Container */
.contact-form-container {
  width: 50%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 30px;
  background: none;
  box-shadow: 15px 15px 30px rgb(25, 25, 25),
              -15px -15px 30px rgb(60, 60, 60);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-container h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form label {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1rem;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.contact-form textarea {
  resize: none;
  height: 150px; /* Set the height of the message textarea */
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.contact-form button {
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.card {
  width: 190px;
  height: 254px;
  border-radius: 30px;
  background: none;
  box-shadow: 15px 15px 30px rgb(25, 25, 25),
              -15px -15px 30px rgb(60, 60, 60);
 }
 
 

/* Media Queries */
@media screen and (max-width: 768px) {
  .contact-container {
    height: 100vh;
  }

  .content,
  .navigation {
    left: 10px;
  }

  .content {
    font-size: 1.5rem;
    bottom: 20px;
  }

  .navigation {
    font-size: 0.8rem;
    bottom: 10px;
  }

  .contact-form-container {
    width: 90%;
    margin: 10px;
    padding: 10px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.9rem;
  }

  .contact-form button {
    font-size: 0.9rem;
  }
}
