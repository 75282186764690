


.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
 /* background-color: #2eaebb;
   background: linear-gradient(to right, #2eaebb, #2e86aa, #302776);
  */
  background-image: url('../assets/bg.png');
  background-size: cover;
  background-position: bottom;
  flex-wrap: wrap; /* Allow wrapping */
}

.footer-content {
  display: flex;
  flex: 3;
  flex-wrap: wrap; /* Allow wrapping */
  justify-content: center; /* Center on smaller screens */
  margin-bottom: 10px; /* Add space at the bottom */
}

.footer-text {
  flex: 12;
  text-align: center;
  color: black;
  margin-bottom: 10px; /* Add space at the bottom */
}

.footer-icons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.icon {
  margin-left: 10px;
  font-size: 1.5rem;
  color: rgb(37, 150, 190);
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .footer-content {
    justify-content: center; /* Center content on smaller screens */
    margin-bottom: 10px; /* Add space at the bottom */
  }

  .footer-text {
    text-align: center;
    margin-bottom: 10px; /* Add space at the bottom */
  }

  .footer-icons {
    justify-content: center; /* Center icons on smaller screens */
  }

  .icon {
    margin: 5px; /* Add some space around icons */
  }
}

@media screen and (max-width: 480px) {
  .footer-content {
    flex: 1; /* Adjust flex for smaller screens */
    justify-content: center; /* Center content on smaller screens */
  }

  .footer-text {
    flex: 1; /* Adjust flex for smaller screens */
    margin-bottom: 10px; /* Add space at the bottom */
  }

  .footer-icons {
    flex: 1; /* Adjust flex for smaller screens */
    justify-content: center; /* Center icons on smaller screens */
  }
}
