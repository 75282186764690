


/* Flex Container 1 */
.flex-container1 {
  opacity: 1;
  display: flex;
  padding: 5px 100px;
  height: 50vh;
 /* background-color: #2eaebb;
 
  background: linear-gradient(to right, #2eaebb, #2e86aa, #302776);
 */
 background-image: url('../assets/bg.png');
 background-size: cover;
 background-position: middle;
  width: 100%; /* Ensure it doesn't overflow */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Columns */
.column {
  flex: 1;
  padding: 10px;
  font: 300 1rem Roboto;
  color: rgb(0, 0, 0);
}

.column > img {
  width: 6rem; 
  height: 2rem; 
  margin-left: 100px;
}

.column > p {
  text-align: justify;
}

/* Contact */
.contact1 {
  color: rgb(0, 0, 0);
  font: 300 1rem Roboto;
}

.contact {
  border-bottom: 1px solid black;
}

/* Client Images */
.client-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%; /* Ensure it doesn't overflow */
  box-sizing: border-box; /* Include padding in width calculation */
}

.client-images img {
  width: 50%;
}

/* Careers */
.careers ul {
  list-style-type: none;
  padding-left: 0;
}

/* Link Style */
.link-style {
  display: block;
  margin: 0;
  padding: 0;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .data-text {
    width: 80%; /* Adjust width for smaller screens */
    font-size: 0.8rem; /* Decrease font size */
  }

  .flex-container1 {
    flex-direction: column; /* Change to column direction for smaller screens */
    padding: 5px 20px; /* Adjust padding for smaller screens */
    height: auto; /* Adjust height for smaller screens */
  }

  .column {
    font-size: 0.8rem; /* Decrease font size */
    padding: 5px; /* Adjust padding for smaller screens */
  }

  .column > img {
    margin-left: 0; /* Adjust margin for smaller screens */
    width: 100%; /* Make images responsive */
    height: auto;
  }

  .client-images {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }

  .client-images img {
    width: 20%; /* Make client images full width */
  }
}
