.contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pd1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url('../assets/products.jpg');
  background-size: cover;
  background-position: center;
   /* Adjust opacity as desired */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5; /* Adjust overlay opacity as desired */
}

.content {
  position: absolute;
  bottom: 80px; /* Position at the bottom */
  left: 20px;; /* Add padding for spacing */
}
.content {
  font-weight: bold;
  font: 900 2rem Roboto;
  color: black;
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 40px; /* Position at the bottom */
  left: 20px; /* Position at the left */
}
.navigation {
  font: 300 1rem Roboto;
  color: white;
  position: absolute;
  padding: 0;
  margin: 0;

  bottom: 20px; /* Position at the bottom */
  left: 20px; /* Position at the left */
}


.navigation span {
  margin-right: 5px; /* Add some spacing between HOME and CONTACT */
}
.contact {
  color: rgb(37, 150, 190);
  border: none;
}

.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-image: url('../assets/122.jpg');
  background-size: cover;
  background-position: center; */
background-color: aliceblue;
  height: auto /* Adjust height as needed */
}

.product-link {
  text-decoration: none;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  margin: 20px;
  color: black;
  cursor: pointer;
  padding: 20px;
  transition: all 0.3s ease; /* Smooth transition on hover */
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  margin-top: 10px;
  width: 200px; /* Adjust the width as needed */
  text-align: center;
}

.product-item:hover {
  transform: scale(1.1); /* Scale on hover */
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-name {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .contact-container {
    height: 100vh; /* Full height on smaller screens */
  }

  .content,
  .navigation {
    left: 10px; /* Adjust left position for smaller screens */
  }

  .content {
    font-size: 1.5rem; /* Decrease font size on smaller screens */
    bottom: 20px; /* Adjust bottom position for smaller screens */
  }

  .navigation {
    font-size: 0.8rem; /* Decrease font size on smaller screens */
    bottom: 10px; /* Adjust bottom position for smaller screens */
  }

  .product-container {
    flex-direction: column;
    height: auto; /* Adjust height for smaller screens */
  }

  .product-item {
    width: 80%; /* Adjust width for smaller screens */
  }
}
