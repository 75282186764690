/* .contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/1.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.5; 
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5; 
}


.content {
  position: absolute;
  bottom: 80px; 
  left: 20px;; 
}
.content {
  font-weight: bold;
  font: 900 2rem Roboto;
  color: black;
  position: absolute;
 
  bottom: 40px; 
  left: 20px; 

}
.navigation {
  font: 300 1rem Roboto;
  color: white;
  position: absolute;
  padding: 0;
  margin: 0;

  bottom: 20px; 
  left: 20px; 

  
}


.navigation span {
  margin-right: 5px; 
}
.contact{
  color: rgb(37, 150, 190);
  border: none;
}



/* .office {
  display: flex;
  text-align: justify;
  width: 50%;
  margin-left: 400px;
  
}

.office h3 {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.office p {
  margin: 5px 0;

  font-size: 0.9rem;
} 

.glass {
  width: 100%;
  height: 50vh;
  background-image: url('../assets/122.jpg');
  background-size: cover;
  background-position: center;
  
 

}
.office {
  display: flex;
  flex-direction: column; 
  text-align: justify;
  width: 50%;
  margin: 20px auto;
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.office h3 {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.office p {
  margin: 5px 0;
  font-size: 0.9rem;
}
 */


 body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/1.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.5; /* Adjust opacity as desired */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5; /* Adjust overlay opacity as desired */
}

.content {
  position: absolute;
  font-weight: bold;
  font-size: 2rem;
  color: white;
  bottom: 40px; /* Position at the bottom */
  left: 20px; /* Add padding for spacing */
}

.navigation {
  font-size: 1rem;
  color: white;
  position: absolute;
  bottom: 20px; /* Position at the bottom */
  left: 20px; /* Position at the left */
}

.navigation span {
  margin-right: 5px; /* Add some spacing between HOME and CONTACT */
}

.contact {
  color: rgb(37, 150, 190);
  border: none;
}

.glass {
  width: 100%;
  padding: 20px;
  background-image: url('../assets/122.jpg');
  background-size: cover;
  background-position: center;
}

.office-info12 {
  text-align: center;
  margin-bottom: 20px;
}

.title1 {
  margin: 0;
  font-size: 1.8rem;
}

.title2 {
  margin: 0;
  font-size: 1.4rem;
}

.office {
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.office h3 {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.office p {
  margin: 5px 0;
  font-size: 1rem;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .contact-container {
    height: 40vh;
  }

  .content {
    font-size: 1.5rem;
    bottom: 20px;
  }

  .navigation {
    font-size: 0.8rem;
    bottom: 10px;
  }
  .glass {
    width: 90%;
   
  }

  .office {
    width: 90%;
    padding: 15px;
  }

  .office p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .contact-container {
    height: 30vh;
  }

  .content {
    font-size: 1.2rem;
    bottom: 10px;
  }

  .navigation {
    font-size: 0.7rem;
    bottom: 5px;
  }
  .glass {
    width: 90%;
   
  }

  .office {
    width: 80%;
    padding: 10px;
  }

  .office p {
    font-size: 0.8rem;
  }
}
